import {
	Dialog,
	DialogContent,
	CircularProgress,
	Typography,
	Box,
} from "@material-ui/core";
import React from "react";

const Spinner = ({ open }) => {
	return (
		<Dialog open={open}>
			<DialogContent>
				<Typography
					variant="body1"
					color="textPrimary"
					className="text-uppercase"
					align="center"
				>
					Loading
				</Typography>
				<Box textAlign="center" p={3}>
					<CircularProgress color="primary" />
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default Spinner;
