import { createStore, action, thunk } from "easy-peasy";
import axios from "axios";
import * as _ from "lodash";

const store = createStore({
	helperData: {},
	user: {},
	serverErrors: "",
	userFilters: [],
	orderFilters: [],
	logFilters: [],
	couponFilters: [],
	setUser: action((state, payload) => {
		state.user = payload;
	}),
	clearUser: action((state, payload) => {
		state.user = {};
	}),
	setServerErrors: action((state, payload) => {
		state.serverErrors = payload;
	}),
	setIngredients: action((state, payload) => {
		state.helperData.ingredients = payload;
	}),
	setPackages: action((state, payload) => {
		state.helperData.packages = payload;
	}),
	setMealPlans: action((state, payload) => {
		state.helperData.mealPlans = payload;
	}),
	setBlogs: action((state, payload) => {
		state.helperData.blogs = payload;
	}),
	setCoupons: action((state, payload) => {
		state.helperData.coupons = payload;
	}),
	setPhrases: action((state, payload) => {
		state.helperData.phrases = payload;
	}),
	setRecipes: action((state, payload) => {
		state.helperData.recipes = payload;
	}),
	setUnits: action((state, payload) => {
		state.helperData.units = payload;
	}),

	setUsers: action((state, payload) => {
		state.helperData.users = payload;
	}),

	setUserTypes: action((state, payload) => {
		state.helperData.userTypes = payload;
	}),

	setInfluencers: action((state, payload) => {
		state.helperData.influencers = payload;
	}),
	setRecipeIngredients: action((state, payload) => {
		state.helperData.recipeIngredients = payload;
	}),

	setRecipeTypes: action((state, payload) => {
		state.helperData.recipeTypes = payload;
	}),
	setAboutUs: action((state, payload) => {
		state.helperData.aboutUs = payload;
	}),
	setCustomerLogs: action((state, payload) => {
		state.helperData.customerLogs = payload;
	}),
	setAdminLogs: action((state, payload) => {
		state.helperData.adminLogs = payload;
	}),
	setOrders: action((state, payload) => {
		state.helperData.orders = payload;
	}),
	setAllUserOrders: action((state, payload) => {
		state.helperData.userOrders = payload;
	}),
	setUserFilters: action((state, payload) => {
		state.userFilters = payload;
	}),
	setOrderFilters: action((state, payload) => {
		state.orderFilters = payload;
	}),
	setLogFilters: action((state, payload) => {
		state.logFilters = payload;
	}),
	setCouponFilters: action((state, payload) => {
		state.couponFilters = payload;
	}),
	setStories: action((state, payload) => {
		state.helperData.stories = payload;
	}),
	setHowItWorks: action((state, payload) => {
		state.helperData.howItWorks = payload;
	}),
	setHomePages: action((state, payload) => {
		state.helperData.homePages = payload;
	}),
	setCategories: action((state, payload) => {
		state.helperData.categories = payload;
	}),
	setTags: action((state, payload) => {
		state.helperData.tags = payload;
	}),
	setServingTypes: action((state, payload) => {
		state.helperData.servingTypes = payload;
	}),
	getRecipes: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, type, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/recipes/${code}`);
					if (_response) actions.setRecipes(_response.data);
					else actions.setRecipes({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
						type: type || "",
						scopes,
					};
					const { data } = await axios.post("/recipes", body);
					actions.setRecipes(data);
				}
			} else {
				const { data } = await axios.post("/recipes");
				actions.setRecipes(data);
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	deleteRecipes: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { codes } = payload;
				if (codes) {
					await axios.delete(`/recipes`, {
						data: {
							codes,
						},
					});
				}
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry, something went wrong. Please try again later",
			);
		}
	}),
	getRecipeTypes: thunk(async (actions, payload) => {
		try {
			let body = {};
			if (payload) {
				const { search, limit } = payload;
				body = {
					filters: {
						search: search || "",
					},
					limit: limit || 100,
				};
			}

			const { data } = await axios.post("/recipe-types", body);
			actions.setRecipeTypes(data);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getAboutUs: thunk(async (actions, payload) => {
		try {
			let body = {};
			if (payload) {
				const { search, limit, scopes } = payload;
				body = {
					filters: {
						search: search || "",
					},
					limit: limit || 100,
					scopes,
				};
			}

			const { data } = await axios.post("/about-us", body);
			actions.setAboutUs(data);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getPhrases: thunk(async (actions, payload) => {
		try {
			let body = {};
			if (payload) {
				const { search, limit } = payload;
				body = {
					filters: {
						search: search || "",
					},
					limit: limit || 100,
				};
			}

			const { data } = await axios.post("/phrases", body);
			actions.setPhrases(data);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getUnits: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code } = payload;
				if (code) {
					const _response = await axios.get(`/units/${code}`);
					if (_response && _response.status === 200)
						actions.setUnits(_response.data);
					else actions.setUnits({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
					};
					const { data } = await axios.post("/units", body);
					actions.setUnits(data);
				}
			} else {
				const { data } = await axios.post("/units", null);
				actions.setUnits(data);
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	deleteUnits: thunk(async (actions, payload) => {
		try {
			await axios.delete("/units", {
				data: { codes: payload },
			});

			await actions.getUnits();
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getIngredients: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/ingredients/${code}`);
					if (_response) actions.setIngredients(_response.data);
					else actions.setIngredients({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/ingredients", body);
					actions.setIngredients(data);
				}
			} else {
				const { data } = await axios.post("/ingredients", null);
				actions.setIngredients(data);
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	deleteIngredients: thunk(async (actions, payload) => {
		try {
			await axios.delete("/ingredients", {
				data: { codes: payload },
			});

			await actions.getIngredients();
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getCoupons: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, type, date } = payload;
				if (code) {
					const _response = await axios.get(`/coupons/${code}`);
					if (_response) actions.setCoupons(_response.data);
					else actions.setCoupons({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
							type:
								type !== "" && type !== "undefined"
									? type
									: "undefined",
							date,
						},
						limit: limit || 100,
					};
					const { data } = await axios.post("/coupons", body);
					actions.setCoupons(data);
				}
			} else {
				const { data } = await axios.post("/coupons", null);
				actions.setCoupons(data);
			}
			const { data } = await axios.post("/coupons", null);
			actions.setCouponFilters(
				_.uniq(_.map(data, "isPercentage")).map((_filter) => {
					return {
						code: _filter,
						name: _filter ? "Percentage" : "Fixed",
					};
				}),
			);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),

	deleteCoupons: thunk(async (actions, payload) => {
		try {
			await axios.delete("/coupons", {
				data: { codes: payload },
			});

			await actions.getCoupons();
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getPackages: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/packages/${code}`);
					if (_response) actions.setPackages(_response.data);
					else actions.setPackages({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/packages", body);
					actions.setPackages(data);
				}
			} else {
				const { data } = await axios.post("/packages", null);
				actions.setPackages(data);
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getMealPlans: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/meal-plans/${code}`);
					if (_response) actions.setMealPlans(_response.data);
					else actions.setMealPlans({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/meal-plans", body);
					actions.setMealPlans(data);
				}
			} else {
				const { data } = await axios.post("/meal-plans", null);
				actions.setMealPlans(data);
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	deleteMealPlans: thunk(async (actions, payload) => {
		try {
			await axios.delete("/meal-plans", {
				data: { codes: [payload] },
			});
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),

	getBlogs: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, id, scopes } = payload;
				if (id) {
					const _response = await axios.get(`/blogs/${id}`);
					if (_response) actions.setBlogs(_response.data);
					else actions.setBlogs({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/blogs", body);
					actions.setBlogs(data);
				}
			} else {
				const { data } = await axios.post("/blogs", null);
				actions.setBlogs(data);
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	deleteBlogs: thunk(async (actions, payload) => {
		try {
			await axios.delete("/blogs", {
				data: { codes: [payload] },
			});
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getUserTypes: thunk(async (actions, payload) => {
		try {
			let body = {};
			if (payload) {
				const { search, limit, scope } = payload;
				body = {
					filters: {
						search: search || "",
					},
					limit: limit || 100,
					scope: scope,
				};
			}

			const { data } = await axios.post("/user-types", body);
			actions.setUserTypes(data);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getInfluencers: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/influencers/${code}`);
					if (_response) actions.setInfluencers(_response.data);
					else actions.setInfluencers({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/influencers", body);
					actions.setInfluencers(data);
				}
			} else {
				const { data } = await axios.post("/influencers", null);
				actions.setInfluencers(data);
			}
		} catch (err) {}
	}),
	deleteInfluencers: thunk(async (actions, payload) => {
		try {
			await axios.delete("/influencers", {
				data: { codes: payload },
			});

			await actions.getInfluencers();
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getRecipeIngredients: thunk(async (actions, payload) => {
		try {
			let body = {};
			if (payload) {
				const { search, limit, recipe, scopes } = payload;
				body = {
					filters: {
						search: search || "",
						recipe: recipe || null,
					},
					limit: limit || 100,
					scopes,
				};
			}

			const { data } = await axios.post("/recipe-ingredients", body);
			actions.setRecipeIngredients(data);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getUsers: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, status, role, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/users/code/${code}`);
					if (_response) actions.setUsers(_response.data);
					else actions.setUsers({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
							status,
							role,
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/users", body);
					actions.setUsers(data);
				}
			} else {
				const { data } = await axios.post("/users", null);
				actions.setUsers(data);
			}

			const { data } = await axios.post("/users", { scopes: ["full"] });
			actions.setUserFilters({
				status: _.uniq(_.map(data, "isActive")).map((_filter) => {
					return {
						code: _filter,
						name: _filter ? "Active" : "Inactive",
					};
				}),
				role: _.uniq(_.map(data, (_o) => _o.role.code)).map(
					(_filter) => {
						return { code: _filter, name: _filter.toLowerCase() };
					},
				),
			});
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	deleteUsers: thunk(async (actions, payload) => {
		try {
			const _response = await axios.delete("/users", {
				data: { codes: [payload] },
			});

			if (_response && _response.status === 200)
				await actions.setUsers(await actions.getUsers());
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getLogs: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, type, action, date } = payload;
				if (code) {
					const _response = await axios.post("/logs");

					if (_response) {
						if (type.toLowerCase() === "admin")
							actions.setAdminLogs(_response.data);
						else if (type.toLowerCase() === "customer")
							actions.setCustomerLogs(_response.data);
						else {
							actions.setAdminLogs({ err: "Not Found" });
							actions.setCustomerLogs({ err: "Not Found" });
						}
					}
				} else {
					const body = {
						filters: {
							search: search || "",
							action,
							date,
						},
						limit: limit || 100,
						type,
					};
					const { data } = await axios.post("/logs", body);
					if (type.toLowerCase() === "admin") {
						actions.setAdminLogs(data);
					} else actions.setCustomerLogs(data);
				}
			} else {
				const { data } = await axios.post("/logs", null);
				actions.setAdminLogs(data);
				actions.setCustomerLogs(data);
			}

			const { data } = await axios.post("/logs", { type: payload.type });
			actions.setLogFilters(
				_.uniq(_.map(data, "action")).map((_filter) => {
					return { code: _filter.toLowerCase(), name: _filter };
				}),
			);
		} catch (err) {
			console.error(err);
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getOrders: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code, status, date, scopes } = payload;
				if (code) {
					const _response = await axios.get(`/orders/${code}`);
					if (_response) actions.setOrders(_response.data);
					else actions.setOrders({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
							status,
							date,
						},
						limit: limit || 100,
						scopes,
					};
					const { data } = await axios.post("/orders", body);
					actions.setOrders(data);
				}
			} else {
				const { data } = await axios.post("/orders", null);
				actions.setOrders(data);
			}

			const { data } = await axios.post("/orders", { scopes: ["full"] });
			actions.setOrderFilters(
				_.uniq(_.map(data, (_o) => _o.status.code)).map((_filter) => {
					return { code: _filter, name: _filter.toLowerCase() };
				}),
			);
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getAllUserOrders: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { code } = payload;
				const _response = await axios.get(`/orders/user/${code}`);
				if (_response) actions.setAllUserOrders(_response.data);
				else actions.setAllUserOrders({ err: "Not found" });
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getStories: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code } = payload;
				if (code) {
					const _response = await axios.get(`/stories/${code}`);
					if (_response) actions.setStories(_response.data);
					else actions.setStories({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
					};
					const { data } = await axios.post("/stories", body);
					actions.setStories(data);
				}
			} else {
				const { data } = await axios.post("/stories", null);
				actions.setStories(data);
			}
		} catch (err) {}
	}),
	updateModuleOrder: thunk(async (actions, payload) => {
		try {
			if (payload) {
				switch (payload.module) {
					case "meal-plan":
						payload.list.forEach(
							async (_p) => await axios.patch("/meal-plans", _p),
						);
						break;
					case "package":
						payload.list.forEach(
							async (_p) => await axios.patch("/packages", _p),
						);
						break;
					case "story":
						payload.list.forEach(
							async (_p) => await axios.patch("/stories", _p),
						);
						break;
					default: {
					}
				}
			}
		} catch (err) {
			actions.setServerErrors(
				"Sorry something went wrong. Please try again",
			);
		}
	}),
	getHowItWorks: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code } = payload;
				if (code) {
					const _response = await axios.get(`/how-it-works/${code}`);
					if (_response) actions.setHowItWorks(_response.data);
					else actions.setHowItWorks({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
					};
					const { data } = await axios.post("/how-it-works", body);
					actions.setHowItWorks(data);
				}
			} else {
				const { data } = await axios.post("/how-it-works", null);
				actions.setHowItWorks(data);
			}
		} catch (err) {}
	}),
	getHomePages: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code } = payload;
				if (code) {
					const _response = await axios.get(`/home-page/${code}`);
					if (_response) actions.setHomePages(_response.data);
					else actions.setHomePages({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
					};
					const { data } = await axios.post("/home-page", body);
					actions.setHomePages(data);
				}
			} else {
				const { data } = await axios.post("/home-page", null);
				actions.setHomePages(data);
			}
		} catch (err) {
			console.log(err);
		}
	}),
	getCategories: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit } = payload;
				const body = {
					filters: {
						search: search || "",
					},
					limit: limit || 100,
				};
				const { data } = await axios.post("/categories", body);
				actions.setCategories(data);
			} else {
				const { data } = await axios.post("/categories", null);
				actions.setCategories(data);
			}
		} catch (err) {}
	}),
	getTags: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit } = payload;
				const body = {
					filters: {
						search: search || "",
					},
					limit: limit || 100,
				};
				const { data } = await axios.post("/tags", body);
				actions.setTags(data);
			} else {
				const { data } = await axios.post("/tags", null);
				actions.setTags(data);
			}
		} catch (err) {}
	}),
	getServingTypes: thunk(async (actions, payload) => {
		try {
			if (payload) {
				const { search, limit, code } = payload;
				if (code) {
					const _response = await axios.get(`/serving-types/${code}`);
					if (_response) actions.setServingTypes(_response.data);
					else actions.setServingTypes({ err: "Not found" });
				} else {
					const body = {
						filters: {
							search: search || "",
						},
						limit: limit || 100,
					};
					const { data } = await axios.post("/serving-types", body);
					actions.setServingTypes(data);
				}
			} else {
				const { data } = await axios.post("/serving-types", null);
				actions.setServingTypes(data);
			}
		} catch (err) {}
	}),
});

export default store;
