import { lazy } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		name: "Login",
		exact: true,
		path: "/login",
		component: lazy(() => import("./Authentication/Login")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Reset Password",
		exact: true,
		path: "/reset-password",
		component: lazy(() => import("./Authentication/ResetPassword")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Change Password",
		exact: true,
		path: "/change-password",
		component: lazy(() => import("./Authentication/ChangePassword")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Dashboard",
		exact: true,
		path: "/dashboard",
		component: lazy(() => import("./Dashboard")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Order History",
		exact: true,
		path: "/order-history",
		component: lazy(() => import("./OrderHistory/Home")),
		isAdmin: true,
		routes: [
			{
				exact: true,
				path: "/order-history/:code",
				component: lazy(() => import("./OrderHistory/View")),
			},
		],
	},
	{
		name: "Users",
		exact: true,
		path: "/users",
		component: lazy(() => import("./Users/Home")),
		isAdmin: true,
		routes: [
			{
				exact: true,
				path: "/users/add",
				component: lazy(() => import("./Users/Add")),
			},
			{
				exact: true,
				path: "/users/edit/:code",
				component: lazy(() => import("./Users/Edit")),
			},
		],
	},
	{
		name: "Ingredients",
		exact: true,
		path: "/ingredients",
		component: lazy(() => import("./Ingredients/Home")),
		isAdmin: true,
		routes: [],
	},
	{
		name: "Groups",
		exact: true,
		path: "/groups",
		component: lazy(() => import("./Groups/Home")),
		isAdmin: true,
		routes: [],
	},
	{
		name: "Units",
		exact: true,
		path: "/units",
		component: lazy(() => import("./Units/Home")),
		isAdmin: true,
		routes: [],
	},
	{
		name: "Recipes",
		exact: true,
		path: "/recipes",
		component: lazy(() => import("./Recipes/Home")),
		isAdmin: true,
		routes: [
			{
				exact: true,
				path: "/recipes/add",
				component: lazy(() => import("./Recipes/Add")),
			},
			{
				exact: true,
				path: "/recipes/edit/:code",
				component: lazy(() => import("./Recipes/Edit")),
			},
		],
	},
	{
		name: "Meal Plans",
		exact: true,
		path: "/meal-plans",
		component: lazy(() => import("./MealPlans/Home")),
		isAdmin: false,
		routes: [
			{
				exact: true,
				path: "/meal-plans/add",
				component: lazy(() => import("./MealPlans/Add")),
			},
			{
				exact: true,
				path: "/meal-plans/edit/:code",
				component: lazy(() => import("./MealPlans/Edit")),
			},
		],
	},
	{
		name: "Blogs",
		exact: true,
		path: "/blogs",
		component: lazy(() => import("./Blogs/Home")),
		isAdmin: false,
		routes: [
			{
				exact: true,
				path: "/blogs/add",
				component: lazy(() => import("./Blogs/Add")),
			},
			{
				exact: true,
				path: "/blogs/edit/:code",
				component: lazy(() => import("./Blogs/Edit")),
			},
		],
	},
	{
		name: "Packages",
		exact: true,
		path: "/packages",
		component: lazy(() => import("./Packages/Home")),
		isAdmin: false,
		routes: [
			{
				exact: true,
				path: "/packages/add",
				component: lazy(() => import("./Packages/Add")),
			},
			{
				exact: true,
				path: "/packages/edit/:code",
				component: lazy(() => import("./Packages/Edit")),
			},
		],
	},
	{
		name: "About Us",
		exact: true,
		path: "/about-us",
		component: lazy(() => import("./AboutUs/Home")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Home Page",
		exact: true,
		path: "/home-page",
		component: lazy(() => import("./HomePage")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Stories",
		exact: true,
		path: "/stories",
		component: lazy(() => import("./Stories/Home")),
		isAdmin: false,
		routes: [
			{
				exact: true,
				path: "/stories/add",
				component: lazy(() => import("./Stories/Add")),
			},
			{
				exact: true,
				path: "/stories/edit/:code",
				component: lazy(() => import("./Stories/Edit")),
			},
		],
	},
	{
		name: "Promo Codes",
		exact: true,
		path: "/promo-codes",
		component: lazy(() => import("./PromoCodes/Home")),
		isAdmin: true,
		routes: [
			{
				exact: true,
				path: "/promo-codes/add",
				component: lazy(() => import("./PromoCodes/Add")),
			},
			{
				exact: true,
				path: "/promo-codes/edit/:code",
				component: lazy(() => import("./PromoCodes/Edit")),
			},
		],
	},
	{
		name: "Influencers",
		exact: true,
		path: "/influencers",
		component: lazy(() => import("./Influencers/Home")),
		isAdmin: true,
		routes: [
			{
				exact: true,
				path: "/influencers/add",
				component: lazy(() => import("./Influencers/Add")),
			},
			{
				exact: true,
				path: "/influencers/edit/:code",
				component: lazy(() => import("./Influencers/Edit")),
			},
		],
	},
	{
		name: "Admin Logs",
		exact: true,
		path: "/admin-logs",
		component: lazy(() => import("./AdminLogs/Home")),
		isAdmin: true,
		routes: [],
	},
	{
		name: "Customer Logs",
		exact: true,
		path: "/customer-logs",
		component: lazy(() => import("./CustomerLogs/Home")),
		isAdmin: true,
		routes: [],
	},
	{
		name: "Customer Profiles",
		exact: true,
		path: "/customer-profiles",
		component: lazy(() => import("./CustomerProfiles/Home")),
		isAdmin: true,
		routes: [
			{
				exact: true,
				path: "/customer-profiles/edit/:code",
				component: lazy(() => import("./CustomerProfiles/Edit")),
			},
		],
	},
	{
		name: "Cycles",
		exact: true,
		path: "/cycles/:code",
		component: lazy(() => import("./Cycles")),
		isAdmin: true,
		routes: [],
	},
	{
		name: "How it works",
		exact: true,
		path: "/how-it-works",
		component: lazy(() => import("./HowItWorks")),
		isAdmin: false,
		routes: [],
	},
	{
		name: "Serving Types",
		exact: true,
		path: "/serving-types",
		component: lazy(() => import("./ServingTypes")),
		isAdmin: true,
		routes: [],
	},
];
