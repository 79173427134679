import { Redirect, Route, Switch, useHistory } from "react-router";
import { Fragment, Suspense, useEffect } from "react";
import modules from "./modules";
import { useStoreState, useStoreActions } from "easy-peasy";
import Spinner from "./components/spinner";

const App = () => {
	const user = useStoreState((state) => state.user);
	const setUser = useStoreActions((action) => action.setUser);
	const history = useHistory();

	useEffect(() => {
		if (Object.keys(user).length === 0 && !localStorage.getItem("_user"))
			history.push("/login");

		if (Object.keys(user).length === 0 && localStorage.getItem("_user"))
			setUser(JSON.parse(localStorage.getItem("_user")));
	}, [user, history, setUser]);

	return (
		<>
			<Switch>
				<Route exact path="/">
					<Redirect to="/login" />
				</Route>
				<Suspense fallback={<Spinner open />}>
					{modules.map((_module, index) => {
						return (
							<Fragment key={index}>
								<Route
									exact={_module.exact}
									path={_module.path}
									component={_module.component}
								/>

								{_module.routes && _module.routes.length > 0
									? _module.routes.map((_subRoute, index) => (
											<Fragment key={index}>
												<Route
													exact={_subRoute.exact}
													path={_subRoute.path}
													component={
														_subRoute.component
													}
												/>
											</Fragment>
									  ))
									: null}
							</Fragment>
						);
					})}
				</Suspense>
			</Switch>
		</>
	);
};

export default App;
