const setUpAxios = (axios) => {
	axios.interceptors.request.use((config) => {
		config.baseUrl = process.env.REACT_APP_API_URL;
		config.url = `${config.baseUrl}${config.url}`;

		const token = localStorage.getItem("_token");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	});

	axios.interceptors.response.use(null, (err) => {
		// console.log(err);
		if (err?.response?.status === 401) {
			window.location.replace("/login");
		} else if (err) {
			// Dunno if I should do this. Might break the app. Should've been this way in the first place.
			throw err;
		}
	});
};

export default setUpAxios;
