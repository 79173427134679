import { createMuiTheme } from "@material-ui/core";
import colors from "./colors";

const theme = createMuiTheme({
	palette: {
		background: {
			paper: colors.paper,
			default: colors.offWhite,
		},
		primary: {
			main: colors.lightGreen,
		},
		secondary: {
			main: colors.black,
		},
		error: {
			main: colors.red,
		},
		warning: {
			main: colors.orange,
		},
		info: {
			main: colors.darkGreen,
		},
		success: {
			main: colors.lightGreen,
		},
		text: {
			primary: colors.black,
			secondary: colors.darkGray,
			disabled: colors.lightGray,
		},
		divider: colors.lightGreen,
	},
	typography: {
		fontFamily: "Avenir",
	},
	// shadows: ["none"],
	overrides: {
		MuiButton: {
			containedPrimary: {
				color: colors.white,
				boxShadow: "none",
				"&:hover": {
					boxShadow: "none",
				},
			},
			textPrimary: {
				color: colors.black,
			},
		},
		MuiDropzoneArea: {
			root: {
				borderColor: colors.lightGray,
			},
			icon: {
				color: colors.lightGray,
				width: "50%",
				height: "50%",
				position: "absolute",
				left: "25%",
			},
		},
		MuiPaper: {
			elevation8: {
				boxShadow: "none",
			},
			elevation4: {
				boxShadow: "none",
			},
			elevation1: {
				boxShadow: "none",
			},
		},
		MuiSnackbarContent: {
			root: {
				backgroundColor: colors.red,
			},
		},
		MuiTablePagination: {
			actions: {
				display: "none",
			},
			toolbar: {
				marginRight: "10px",
			},
		},
		MuiTableCell: {
			head: {
				textTransform: "uppercase",
				fontWeight: "bolder",
			},
			root: {
				borderBottom: "none",
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: colors.white,
			},
			head: {
				backgroundColor: colors.lightGray,
			},
		},
		MuiInputBase: {
			root: {
				backgroundColor: colors.white,
			},
		},
	},
});

export default theme;
