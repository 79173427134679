import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import setUpAxios from "./setUpAxios";
import axios from "axios";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { StoreProvider } from "easy-peasy";
import theme from "./theme";
import "./styles.css";
import store from "./store";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV === "production") {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}
setUpAxios(axios);

ReactDOM.render(
	<StoreProvider store={store}>
		<Router>
			<ThemeProvider theme={theme}>
				<CssBaseline>
					<App />
				</CssBaseline>
			</ThemeProvider>
		</Router>
	</StoreProvider>,
	document.getElementById("root"),
);
