const colors = {
	white: "#FFFFFF",
	offWhite: "#FAFAFA",
	lightGray: "#D0D1D3",
	darkGreen: "#00C7A9",
	lightGreen: "#87C7C4",
	darkGray: "#747373",
	black: "#333333",
	red: "#F44236",
	orange: "#FF8900",
	lightBlue: "#90C9F9",
	paper: "#F0F0F0",
};

export default colors;
